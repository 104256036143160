import { useEffect, useState } from "react";

import { Flex, List, Pagination } from "antd";

import type {
  ListRatioPresetGroupsV2ResponseBody,
  RatioPreset,
} from "@omi-lab/atlas-typescript";

import { useClientsStore } from "src/store/clients";

type RatioPresetCardProps = {
  ratioPreset: RatioPreset;
};

const RatioPresetCard = ({ ratioPreset }: RatioPresetCardProps) => {
  return (
    <>
      <List.Item key={ratioPreset.name} title={ratioPreset.name ?? ""}>
        <List.Item.Meta
          title={ratioPreset.name}
          description={
            <div className="flex-col flex">
              <div>{`preset name: ${ratioPreset.name ?? "N/A"}`}</div>
              <div>{`size: [${ratioPreset.width}x${ratioPreset.createdAt}]`}</div>
              <div>{`slug: ${ratioPreset.slug}`}</div>
            </div>
          }
        />
      </List.Item>
    </>
  );
};

const PAGE_SIZE = 10;

export const RatioPresets = () => {
  const { ratioPresetsClient } = useClientsStore((state) => state);

  const [page, setPage] = useState(1);
  const [ratioPresetGroups, setRatioPresetGroups] =
    useState<ListRatioPresetGroupsV2ResponseBody>();

  useEffect(() => {
    const listRatioPresets = async () =>
      ratioPresetsClient
        .listRatioPresetGroupsV2({
          page,
          pageSize: PAGE_SIZE,
        })
        .then((response) => {
          setRatioPresetGroups(response.data);
        });

    listRatioPresets();
  }, [ratioPresetsClient, page]);

  return (
    <>
      <Flex gap="middle" vertical style={{ padding: "1rem" }}>
        {ratioPresetGroups?.data.map((group) => (
          <>
            <h2>{group.name}</h2>
            <List
              itemLayout="vertical"
              size="large"
              dataSource={group.presets ?? []}
              key={JSON.stringify(group.presets)}
              renderItem={(item) => <RatioPresetCard ratioPreset={item} />}
            />
          </>
        ))}
        <Pagination
          current={page}
          pageSize={PAGE_SIZE}
          total={ratioPresetGroups?.count ?? 0}
          onChange={setPage}
        />
      </Flex>
    </>
  );
};

export default RatioPresets;
